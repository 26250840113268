<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('dae_reports.stock_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('dae_config.circular_type')"
                  label-for="circular_type"
                  >
                  <b-form-select
                      plain
                      v-model="search.circular_type"
                      :options="circularTypeList"
                      id="circular_type"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('dae_subsidy.stock_type')"
                  label-for="type"
                  >
                  <b-form-select
                      plain
                      v-model="search.type"
                      :options="stockTypeList"
                      id="type"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_district.district') }}
                    </template>
                    <v-select name="district_id"
                      v-model="search.district_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= districtList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazilla_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla') }}
                    </template>
                    <v-select name="district_id"
                      v-model="search.upazilla_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= upazilaList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('dae_reports.stock_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            name="stock-report.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('dae_reports.stock_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                    <b-row class="my-4">
                                      <b-col md="4"><strong>{{ $t('dae_config.circular_type') }}: </strong>{{ search.circular_type ? ($i18n.locale === 'en' ? searchResult.circular_type_name : searchResult.circular_type_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('dae_subsidy.stock_type') }}: </strong>{{ search.type ? ($i18n.locale === 'en' ? searchResult.stock_type_name : searchResult.stock_type_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('globalTrans.division') }}: </strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchResult.division_name : searchResult.division_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('globalTrans.district') }}: </strong>{{ search.district_id ? ($i18n.locale === 'en' ? searchResult.district_name : searchResult.district_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('globalTrans.upazila') }}: </strong>{{ search.upazilla_id ? ($i18n.locale === 'en' ? searchResult.upazilla_name : searchResult.upazilla_name_bn) : $t('globalTrans.all')  }}</b-col>
                                    </b-row>
                                  </div>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover row-hovered small caption-top responsive show-empty>
                                        <b-thead>
                                          <b-tr>
                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.stock_type') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_reports.item_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_reports.current_stock') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.stock_type_name_bn : info.stock_type_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.item_name_bn : info.item_name }}</b-td>
                                          <b-td class="text-center">{{ $n(info.total_quantity, { useGrouping: false }) + ' ' + getKgOrPcs(info.type) }}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { stockReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import Pdf from './pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
      ListReportHead
    },
    created () {
      this.setAuthLocationInfo()
    },
    data () {
      return {
        search: {
          circular_type: 0,
          type: 0,
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        },
        searchResult: {
          division_name: '',
          division_name_bn: '',
          district_name: '',
          district_name_bn: '',
          upazilla_name: '',
          upazilla_name_bn: '',
          circular_type_name: '',
          circular_type_name_bn: '',
          stock_type_name: '',
          stock_type_name_bn: ''
        },
        incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
        datas: [],
        loading: false,
        showData: false,
        districtList: [],
        upazilaList: [],
        authLocation: {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        }
      }
    },
    mounted () {
      core.index()
      this.headerDataExcel()
    },
    computed: {
      circularTypeList: function () {
        return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(item => item.value !== 4)
      },
      stockTypeList: function () {
        return this.$store.state.incentiveGrant.commonObj.stockTypeList
      },
      divisionList: function () {
        let divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        if (this.authLocation.division_id) {
          divisionList = divisionList.filter(item => item.value === this.authLocation.division_id)
        }
        return divisionList
      },
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[1] = this.$t('dae_reports.stock_report')
          headerVal[2] = this.$t('dae_config.circular_type') + ':  ' + this.searchResult.circular_type_name + '; ' + ' ' + this.$t('dae_subsidy.stock_type') + ':  ' + this.searchResult.stock_type_name
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.searchResult.division_name + '; ' + ' ' + this.$t('globalTrans.district') + ':  ' + this.searchResult.district_name
          headerVal[4] = this.$t('globalTrans.upazila') + ':  ' + this.searchResult.upazilla_name
        } else {
          headerVal[1] = this.$t('dae_reports.stock_report')
          headerVal[2] = this.$t('dae_config.circular_type') + ':  ' + this.searchResult.circular_type_name_bn + '; ' + ' ' + this.$t('dae_subsidy.stock_type') + ':  ' + this.searchResult.stock_type_name_bn
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.searchResult.division_name_bn + '; ' + ' ' + this.$t('globalTrans.district') + ':  ' + this.searchResult.district_name_bn
          headerVal[4] = this.$t('globalTrans.upazila') + ':  ' + this.searchResult.upazilla_name_bn
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
          if (this.$i18n.locale === 'en') {
            return {
              'sl ': serial,
              'Circular Type': keyItem.circular_type_name,
              'Stock Type': keyItem.stock_type_name,
              'Item Name': keyItem.item_name,
              'Current Stock': keyItem.total_quantity + ' ' + this.getKgOrPcs(keyItem.type)
            }
          } else {
            return {
              'ক্রমিক সংখ্যা': this.$n(serial),
              'বিজ্ঞপ্তি টাইপ ': keyItem.circular_type_name_bn,
              'স্টক টাইপ ': keyItem.stock_type_name_bn,
              'আইটেম নাম': keyItem.item_name_bn,
              'বর্তমান স্টক': keyItem.total_quantity + ' ' + this.getKgOrPcs(keyItem.type)
            }
          }
        })
        return listContractor
      }
    },
    watch: {
      'search.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazilaList = this.getUpazilaList(newVal)
        }
      }
    },
    methods: {
      headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/2')
        .then(response => {
          if (response.success) {
            // this.headerExcelDefault.orgName = orgName
            // this.headerExcelDefault.orgNameBn = orgNameBn
            // this.headerExcelDefault.address = response.data.address
            // this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      pdfExport () {
        const reportTitle = this.$t('dae_reports.stock_report')
        Pdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.search)
      },
      async searchData () {
        this.loading = true
        this.showData = true
        const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, stockReport, params).then(response => {
        if (response.success) {
            if (response.data) {
              if (this.search.division_id) {
                const division = this.$store.state.commonObj.divisionList.find(div => div.value === this.search.division_id)
                this.searchResult.division_name = division !== undefined ? division.text_en : ''
                this.searchResult.division_name_bn = division !== undefined ? division.text_bn : ''
              }
              if (this.search.district_id) {
                const district = this.$store.state.commonObj.districtList.find(div => div.value === this.search.district_id)
                this.searchResult.district_name = district !== undefined ? district.text_en : ''
                this.searchResult.district_name_bn = district !== undefined ? district.text_bn : ''
              }
              if (this.search.upazilla_id) {
                const upazilla = this.$store.state.commonObj.upazilaList.find(div => div.value === this.search.upazilla_id)
                this.searchResult.upazilla_name = upazilla !== undefined ? upazilla.text_en : ''
                this.searchResult.upazilla_name_bn = upazilla !== undefined ? upazilla.text_bn : ''
              }
              if (this.search.circular_type) {
                const circular = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.find(div => div.value === this.search.circular_type)
                this.searchResult.circular_type_name = circular !== undefined ? circular.text_en : ''
                this.searchResult.circular_type_name_bn = circular !== undefined ? circular.text_bn : ''
              }
              if (this.search.type) {
                const type = this.stockTypeList.find(div => div.value === this.search.type)
                this.searchResult.stock_type_name = type !== undefined ? type.text_en : ''
                this.searchResult.stock_type_name_bn = type !== undefined ? type.text_bn : ''
              }
              this.datas = this.getRelationalData(response.data)
            }
        } else {
            this.datas = []
        }
        })
        this.loading = false
      },
      getRelationalData (data) {
        const CircularTypeStore = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
        const divisionStore = this.$store.state.commonObj.divisionList
        const districtStore = this.$store.state.commonObj.districtList
        const upazillaStore = this.$store.state.commonObj.upazilaList
        const cropList = this.$store.state.incentiveGrant.commonObj.cropList
        const instrumentList = this.$store.state.incentiveGrant.commonObj.instrumentList
        const materialList = this.$store.state.incentiveGrant.commonObj.agMaterialList

        const listData = data.map(item => {
          const circularObject = CircularTypeStore.find(cir => cir.value === item.circular_type)
          const stockTypeObject = this.stockTypeList.find(stk => stk.value === item.type)
          const divisionObject = divisionStore.find(div => div.value === item.division_id)
          const districtObject = districtStore.find(dist => dist.value === item.district_id)
          const upazillaObject = upazillaStore.find(upz => upz.value === item.upazilla_id)

          let itemObject
          if (item.type === 1) {
            itemObject = instrumentList.find(inst => inst.value === parseInt(item.item_id))
          } else if (item.type === 2) {
            itemObject = cropList.find(crp => crp.value === parseInt(item.item_id))
          } else if (item.type === 3) {
            itemObject = materialList.find(mat => mat.value === parseInt(item.item_id))
          }
          const circularTypeData = {
            circular_type_name: circularObject !== undefined ? circularObject.text_en : ' ',
            circular_type_name_bn: circularObject !== undefined ? circularObject.text_bn : ' '
          }
          const stockTypeData = {
            stock_type_name: stockTypeObject !== undefined ? stockTypeObject.text_en : ' ',
            stock_type_name_bn: stockTypeObject !== undefined ? stockTypeObject.text_bn : ' '
          }
          const divisionData = {
            division_name: divisionObject !== undefined ? divisionObject.text_en : ' ',
            division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ' '
          }
          const districtData = {
            district_name: districtObject !== undefined ? districtObject.text_en : ' ',
            district_name_bn: districtObject !== undefined ? districtObject.text_bn : ' '
          }
          const upazillaData = {
            upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : ' ',
            upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ' '
          }
          const itemData = {
            item_name: itemObject !== undefined ? itemObject.text_en : ' ',
            item_name_bn: itemObject !== undefined ? itemObject.text_bn : ' '
          }
          const quantityData = {
            quantity: this.$n(item.total_quantity, { useGrouping: false }) + ' ' + this.getKgOrPcs(item.type)
          }

          return Object.assign({}, item, circularTypeData, stockTypeData, divisionData, districtData, upazillaData, itemData, quantityData)
        })

        return listData
      },
      getDistrictList (DivisionId) {
        let list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === DivisionId)
        if (this.authLocation.district_id) {
          list = list.filter(item => item.value === this.authLocation.district_id)
        }
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      getUpazilaList (districtId = null) {
        let upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (this.authLocation.upazilla_id) {
          upazilaList = upazilaList.filter(item => item.value === this.authLocation.upazilla_id)
        }
        if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
      },
      getKgOrPcs (type) {
        if (type === 2 || type === 3) {
          return this.$t('dae_reports.kg')
        }
        return this.$t('dae_reports.pcs')
      },
      setAuthLocationInfo () {
        let location = {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        }
        const authUser = this.$store.state.Auth.authUser
        if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
          const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
          if (typeof office !== 'undefined') {
            location = {
              division_id: office.division_id,
              district_id: office.district_id,
              upazilla_id: office.upazilla_id
            }
          }
        }
        this.search = Object.assign({}, this.search, location)
      }
  }
}
</script>
